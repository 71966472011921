<template>
  <article id="error_main">
    <div id="logo_container">
      <h1 id="logo">Vegaani</h1>
      <h1 id="logo2">Kaveri</h1>
    </div>
    <section id="error_container">
      <h2 class="error_h2">VegaaniKaveri ajautui virheeseen :(</h2>
      <p class="errorMsg">
        Virhe: <br />
        "{{ error }}"
      </p>
      <button class="exit" v-on:click="emitClose">
        Poistu
      </button>
    </section>
  </article>
</template>

<script>
export default {
  name: "error",
  props: ["errorMessage"],
  data: function() {
    return {
      error: this.errorMessage
    };
  },
  methods: {
    emitClose() {
      this.$emit("closeError", false);
    }
  }
};
</script>

<style lang="scss" scoped>
#error_main {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #1c5f51;
  min-width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  max-height: 100%;
}
article {
  justify-content: center;
}
#logo_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1em auto;
}
#logo {
  font-size: 3.5em;
  color: #40b782ff;
}
#logo2 {
  font-size: 3.5em;
  color: #344a5dff;
}
#error_container {
  all: unset;
  animation: fadeIn 0.3s forwards ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 85vw;
  max-height: 85vh;
  margin: 1em auto;
  padding: 1em;
  background-color: #004235;
  border: 0.1em solid black;
  border-radius: 0.5em;
}
.error_h2 {
  text-decoration: underline;
  text-decoration-color: black;
}
.error_p {
  display: inline-block;
  max-width: inherit;
  max-height: inherit;
  border: 0.1em solid black;
  border-radius: 0.35em;
  padding: 0.5em;
}
.errorMsg {
  display: inline-block;
  max-width: inherit;
  max-height: inherit;
  border: 0.1em solid black;
  border-radius: 0.25em;
  background-color: #2d0042;
  padding: 0.5em;
}
.exit {
  all: unset;
  cursor: pointer;
  margin: 0.5em auto;
  color: white;
  background-color: #003026;
  border: 0.1em solid black;
  border-radius: 0.5em;
  padding: 1em;
}
@supports (-webkit-text-stroke: 0.025em black) {
  #logo {
    -webkit-text-stroke: 0.025em black;
  }
  #logo2 {
    -webkit-text-stroke: 0.025em black;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    color: rgba(215, 250, 242, 0);
  }
  100% {
    opacity: 1;
    color: rgb(215, 250, 242);
  }
}
</style>
