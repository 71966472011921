<template>
  <article id="loading_main">
    <img id="loading_icon" :src="require(`../assets/lloading.svg`)" />
  </article>
</template>

<script>
export default {
  name: "loading",
  data: function() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
#loading_main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  animation: main_fadeIn 0.15s forwards;
}
#loading_icon {
  margin: 0 auto;
  max-width: 50vw;
  animation: icon_fadeIn 0.15s forwards, loading ease-in-out 2s infinite;
}
@keyframes main_fadeIn {
  0% {
    background-color: rgba($color: #001a17, $alpha: 0.33);
  }
  50% {
    background-color: rgba($color: #001a17, $alpha: 0.66);
  }
  100% {
    background-color: rgba($color: #001a17, $alpha: 0.99);
  }
}
@keyframes icon_fadeIn {
  0% {
    opacity: 0.33;
  }
  50% {
    opacity: 0.66;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loading {
  0% {
    filter: drop-shadow(-0.15em -0.15em rgb(104, 194, 160));
    transform: rotate(0deg);
  }
  50% {
    filter: drop-shadow(-0.15em -0.15em rgb(104, 194, 182));
  }
  100% {
    filter: drop-shadow(-0.15em -0.15em rgb(104, 194, 167));
    transform: rotate(360deg);
  }
}
</style>
