const POSSIBLES = [
  {
    ekoodi: ["E270", "E 270"],
    nimet: ["Maitohappo", "Laktaatti"],
    kuvaus: [
      "Mahdollisesti eläinperäinen. Tuotetaan maitohappokäymisen avulla lähes poikkeuksetta kasvitärkkelyksestä. Lääkkeissä ja kosmetiikassa saattaa olla eläinperäinen."
    ]
  },
  {
    ekoodi: ["E304", "E 304"],
    nimet: ["Askorbyylipalmitaatti", "Askorbyylistearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E322", "E 322"],
    nimet: ["Lesitiinit", "Lesitiini"],
    kuvaus: [
      "Mahdollisesti eläinperäinen. Useimmiten peräisin soijasta tai auringonkukasta."
    ]
  },
  {
    ekoodi: ["E325", "E 325"],
    nimet: ["Natriumlaktaatti", "Maitohapon suola"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E326", "E 326"],
    nimet: ["Kaliumlaktaatti", "Maitohapon suola"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E327", "E 327"],
    nimet: ["Kalsiumlaktaatti", "Maitohapon suola"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E422", "E 422", "E 422*"],
    nimet: ["Glyseroli", "glyseriini"],
    kuvaus: [
      "Mahdollisesti eläinperäinen. Käytetään laajasti kosmetiikkatuotteissa, myös ruuissa makeutus-, emulgointi- ja täyteaineena. Voi olla peräisin eläin- tai kasviperäisistä rasvoista."
    ]
  },
  {
    ekoodi: ["E431", "E 431"],
    nimet: ["Polyoksietyleeni", "Stearaatti", "Polyoksietyleeni(40)stearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E432", "E 432"],
    nimet: ["Polyoksietyleenisorbitaanimonolauraatti", "polysorbaatti 20"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E433", "E 433"],
    nimet: ["Polyoksietyleenisorbitaanimono-oleaatti", "polysorbaatti 80"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E434", "E 434"],
    nimet: ["Polyoksietyleenisorbitaanimonopalmitaatti", "polysorbaatti 40"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E435", "E 435"],
    nimet: ["Polyoksietyleenisorbitaanimonostearaatti", "polysorbaatti 60"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E436", "E 436"],
    nimet: ["Polyoksietyleenisorbitaaninitristearaatti", "polysorbaatti 65"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E435", "E 435"],
    nimet: ["Polyoksietyleenisorbitaanimonostearaatti", "polysorbaatti 60"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E445", "E 445"],
    nimet: ["Puuhartsien glyseroliesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E470a", "E 470a"],
    nimet: ["Rasvahappojen natrium-, kalium- ja kalsiumsuolat"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E470b", "E 470b"],
    nimet: ["Rasvahappojen magnesiumsuolat"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E471", "E 471"],
    nimet: ["Rasvahappojen mono- ja diglyseridit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472a", "E 472a"],
    nimet: ["Rasvahappojen mono- ja diglyseridien etikkahappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472b", "E 472b"],
    nimet: ["Rasvahappojen mono- ja diglyseridien maitohappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472c", "E 472c"],
    nimet: ["Rasvahappojen mono- ja diglyseridien sitruunahappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472d", "E 472d"],
    nimet: ["Rasvahappojen mono- ja diglyseridien viinihappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472e", "E 472e"],
    nimet: ["Rasvahappojen mono- ja diglyseridien viinihappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472f", "E 472f"],
    nimet: [
      "Rasvahappojen mono- ja diglyseridien etikka- ja viinihappoesterit"
    ],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E473", "E 473"],
    nimet: ["Rasvahappojen sakkaroosiesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E474", "E 474"],
    nimet: ["Sokeriglyseridit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E475", "E 475"],
    nimet: ["Rasvahappojen polyglyseroliesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E476", "E 476"],
    nimet: ["Polyglyserolipolyrisinolaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E477", "E 477"],
    nimet: ["Propyleeniglykolimonostearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E479b", "E 479b"],
    nimet: ["Termisesti hapetettu ja polymeroitu soijaöljy", "TOSOM"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E4811", "E 4811"],
    nimet: ["Natriumstearoyyli-2-laktylaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E4831", "E 4831"],
    nimet: ["Stearyylitartraatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E4821", "E 4821"],
    nimet: ["Kalsiumstearoyyli-2-laktylaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E491", "E 491"],
    nimet: ["Sorbitaanimonostearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E492", "E 492"],
    nimet: ["Sorbitaanitristearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E493", "E 493"],
    nimet: ["Sorbitaanimonolauraatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E494", "E 494"],
    nimet: ["Sorbitaanimono-oletaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E495", "E 495"],
    nimet: ["Sorbitaanimonopalmitatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E570", "E 570"],
    nimet: ["Rasvahapot"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: [
      "E626",
      "E 626",
      "E627",
      "E 627",
      "E628",
      "E 628",
      "E629",
      "E 629"
    ],
    nimet: ["Guanyylihappo suoloineen"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: [
      "E630",
      "E 630",
      "E631",
      "E 631",
      "E632",
      "E 632",
      "E633",
      "E 633"
    ],
    nimet: ["Inosiinihappo suoloineen"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E634", "E 634"],
    nimet: ["Kalsium-5′-ribonukleotidit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E635", "E 635"],
    nimet: ["Dinatrium-5′-ribonukleotidit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E920", "E 920"],
    nimet: ["L-kysteiini", "Aminohappo"],
    kuvaus: [
      "Mahdollisesti eläinperäinen. Löytyy terveysvalmisteista ja kosmetiikkatuotteista, muualla maailmassa käytetään laajasti teollisissa leipomotuotteissa. Voi olla synteettinen tai peräisin hiuksista tai höyhenistä."
    ]
  },
  {
    ekoodi: ["E1518", "E 1518"],
    nimet: ["Glyserolitriasetaatti", "triasetiini§"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  }
];
module.exports = POSSIBLES;
