<template>
  <article id="first_main">
    <div id="logo_container">
      <h1 id="logo">Vegaani</h1>
      <h1 id="logo2">Kaveri</h1>
    </div>
    <section id="welcome_container">
      <h2 v-if="welcome_1 && !welcome_2">Tervetuloa</h2>
      <p class="welcome_p" v-if="welcome_1 && !welcome_2">
        VegaaniKaveri auttaa tunnistamaan eläinperäisiä ainesosia.
      </p>
      <button
        class="continue"
        v-if="welcome_1 && !welcome_2"
        v-on:click="
          welcome_1 = false;
          welcome_2 = true;
        "
      >
        Jatka
      </button>
      <p class="welcome_p" v-if="welcome_2 && !welcome_1">
        Näytä VegaaniKaverille tuotteselosteen suomenkielistä ainesosaluetteloa.
      </p>
      <p class="welcome_p" v-if="welcome_2 && !welcome_1">
        Suomenkielisestä ainesosaluettelosta VegaaniKaveri tunnistaa ainesosat
        jotka viittaavat eläinperäisyyteen.
      </p>
      <button
        class="continue"
        v-if="welcome_2 && !welcome_1"
        v-on:click="
          welcome_2 = false;
          welcome_3 = true;
        "
      >
        Jatka
      </button>
      <p class="welcome_p" v-if="welcome_3 && !welcome_2">
        Jotta VegaaniKaveri tunnistaisi ainesosat oikein, täytyy Suomenkielisen
        ainesosaluettelon näkyä kuvassa siten, että saisit luettua sen itsekkin.
      </p>
      <p class="welcome_p" v-if="welcome_3 && !welcome_2">
        Nyt voit jatkaa sovellukseen. Tämä ohjeistus näytetään vain
        ensimmäisellä vierailullasi.
      </p>
      <button v-if="welcome_3" class="continue" v-on:click="emitClose">
        Jatka sovellukseen
      </button>
    </section>
  </article>
</template>

<script>
export default {
  name: "first",
  data: function() {
    return {
      welcome_1: true,
      welcome_2: false,
      welcome_3: false
    };
  },
  methods: {
    emitClose() {
      this.$emit("closeFirst", false);
    }
  }
};
</script>

<style lang="scss" scoped>
#first_main {
  display: flex;
  flex-direction: column;
  background-color: #1c5f51;
  min-width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  max-height: 100%;
}
article {
  justify-content: center;
}
#logo_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1em auto;
}
#logo {
  font-size: 3.5em;
  color: #40b782ff;
}
#logo2 {
  font-size: 3.5em;
  color: #344a5dff;
}
#welcome_container {
  all: unset;
  animation: fadeIn 0.3s forwards ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 85vw;
  max-height: 85vh;
  margin: 1em auto;
  padding: 1em;
  background-color: #004235;
  border: 0.1em solid black;
  border-radius: 0.5em;
}
.welcome_p {
  display: inline-block;
  max-width: inherit;
  max-height: inherit;
  border: 0.1em solid black;
  border-radius: 0.35em;
  padding: 0.5em;
}
.continue {
  all: unset;
  cursor: pointer;
  margin: 0.5em auto;
  color: white;
  background-color: #003026;
  border: 0.1em solid black;
  border-radius: 0.5em;
  padding: 1em;
}
@supports (-webkit-text-stroke: 0.025em black) {
  #logo {
    -webkit-text-stroke: 0.025em black;
  }
  #logo2 {
    -webkit-text-stroke: 0.025em black;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    color: rgba(215, 250, 242, 0);
  }
  100% {
    opacity: 1;
    color: rgb(215, 250, 242);
  }
}
</style>
