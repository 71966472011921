<template>
  <article id="result_main">
    <section id="recent_container">
      <ul class="recent_ul" v-for="(recent, n) in recents" v-bind:key="n">
        <li class="recent_li">
          <p class="recent_n">{{ n + 1 }}</p>
          <div class="recent_v" v-if="recent.length > 0">
            <h4 class="recent_h4">Eläinperäisiä ainesosia:</h4>
            <p class="recent_p">{{ recent }}</p>
          </div>
          <div class="recent_v possible" v-if="possibles[n].length > 0">
            <h4 class="recent_h4">Mahdollisesti eläinperäisiä ainesosia:</h4>
            <p class="recent_p">{{ possibles[n] }}</p>
          </div>
          <div
            class="recent_v"
            v-if="recent.length <= 0 && possibles[n].length <= 0"
          >
            <p class="recent_p">
              VegaaniKaveri ei löytänyt eläinperäisiin ainesosiin viittaavia
              sanoja.
            </p>
          </div>
          <details
            class="recent_details"
            aria-label="Kuva jota VegeKamu käytti ainesosien tunnistamiseen"
          >
            <summary class="recent_summary"
              >Kuva jota VegaaniKaveri käytti</summary
            >
            <img
              class="recent_img"
              v-bind:src="'data:image/png;base64,' + captures[n]"
            />
          </details>
        </li>
      </ul>
      <button id="back_button" v-on:click="emitClose">Takaisin</button>
    </section>
  </article>
</template>

<script>
/* eslint-disable */
export default {
  name: "result",
  props: ["recognized", "images"],
  data: function() {
    return {
      recents: [],
      possibleTargets: [],
      possibles: [],
      captures: [],
      n: 0,
      y: 0
    };
  },
  watch: {
    recognized: function(value) {
      let n = this.n;
      let possiblesPrepare = this.possibleTargets[0].flat(1)
      let possiblesLowCase = possiblesPrepare.map(e => e.toLowerCase());
      let fromParent = value[n].split(', ');
      const joining = possiblesLowCase.filter(e => fromParent.includes(e));
      const joining2 = possiblesLowCase.filter(e =>
        fromParent[0].includes(e)
      );
      const finalJoining = joining.concat(joining2); //make on array of both joinings
      let ready = [...new Set(finalJoining)];
      this.possibles.push(ready.toString());
      let final = fromParent.filter(val => !ready.includes(val));
      this.recents.push(final.join(", ").toString());
      this.n++;
      console.log(n)
    },
    images: function(value) {
      let y = this.y;
      this.captures.push(value[y]);
      this.y++;
    }
  },
  mounted(){
    const POSSIBLES = require("../targets/possiblyNonVegan");
    const EKOODIT = [];
    const NIMET = [];
    for (const POSSIBLE of POSSIBLES) {
      EKOODIT.push(POSSIBLE.ekoodi);
      NIMET.push(POSSIBLE.nimet);
    }
    const MOLEMMAT = EKOODIT.concat(NIMET);
    /*Push both the names and e-codes of non-vegan ingredients to one single "targets" array*/
    this.possibleTargets.push(MOLEMMAT);
  },
  methods: {
    emitClose() {
      this.$emit("closeResult", false);
    }
  }
};
</script>
<style lang="scss" scoped>
#result_main {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 50;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: stretch;
  color: white;
  background-color: rgba($color: #001a17, $alpha: 0.99);
}
#recent_container {
  display: flex;
  z-index: 51;
  border: 0.1em solid black;
  padding: 1em;
  border-radius: 0.75em;
  max-width: 85vw;
  max-height: 85vh;
  overflow: auto;
  flex-direction: column;
  margin: 1em auto;
  background-color: rgba($color: #005f63, $alpha: 1);
  animation: slideUp ease-in-out 0.5s forwards;
}
#back_button {
  margin: 0.5em auto;
  cursor: pointer;
  color: white;
  background-color: #004235;
  border: 0.1em solid black;
  border-radius: 0.5em;
  padding: 1em;
}
.recent_ul {
  display: flex;
  justify-content: center;
  margin: 1em auto 0;
  flex-direction: column;
  max-width: 85vw;
  max-height: 85vh;
  width: 100%;
  text-transform: capitalize;
  overflow-x: visible;
}
.recent_li {
  display: inline-block;
  max-width: inherit;
  max-height: inherit;
  filter: drop-shadow(-0.1em 0.1em #002720);
  width: 100%;
  border: 0.1em solid black;
  border-radius: 0.35em;
  background-color: rgba($color: #073028, $alpha: 0.95);
  text-transform: capitalize;
}
.recent_n {
  font-size: 0.85em;
  text-align: left;
  margin: 0.33em;
}
.recent_v {
  font-size: 0.9em;
  text-align: left;
  max-width: inherit;
  max-height: inherit;
  filter: drop-shadow(-0.1em 0.05em #001b16);
  border: 0.1em solid black;
  background-color: rgba($color: #00352a, $alpha: 0.55);
  border-radius: 0.25em 0.25em 0.25em 0;
  margin: 0.5em 0.5em 0;
  padding: 0.5em;
}
.recent_v.possible {
  background-color: rgba($color: #01423f, $alpha: 0.55);
  border-radius: 0 0.25em 0.25em 0;
}
.recent_h4 {
  margin: 0.25em auto;
  text-decoration: underline;
}
.recent_p {
  margin: 0.1em auto;
}
.recent_details{
  margin: 0 0.5em 0.5em;
  filter: drop-shadow(-0.1em 0.05em #001b16);
}
.recent_summary {
  display: inline-block;
  padding: 0.5em;
  border: 0.125em solid black;
  border-radius: 0 0 0.25em 0.25em;
  background-color: rgba($color: rgb(0, 92, 80), $alpha: 0.55);
  margin: 0 0 auto;
  font-size: 0.65em;
}
.recent_img {
  width: 100%;
  height: auto;
  display: block;
  border: 0.1em solid black;
  border-radius: 0 0.25em 0.25em 0.25em;
  animation: summaryImg ease-in-out 0.3s forwards;
}
@keyframes slideUp {
  0% {
    margin-top: 100vh;
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes summaryImg {
  0% {
    opacity: 0;
    height: 0;
    width: 0;
  }
  50% {
    opacity: 0.5;
    height: 100%;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}
</style>