/*Preparing arrays that contain non-vegan ingredients*/
const TARGETS = [
  //Including misspelled words, just incase. Some of the word variations could probably be trimmed out of this list. Please do a pull request if you want to make any modifications, help is very much appreciated. :)
  {
    ekoodi: ["E120", "E 120"],
    nimet: ["Karmiini", "Kokkiniili", "Karmiinihappo"],
    kuvaus: [
      "Varmasti eläinperäinen. Kuivatuista kokkiniilikirvoista peräisin olevaa punaista väriainetta."
    ] //TARGETS.kuvaus is not currently in use anywhere, at the moment it's included just for possible future features.
  },
  {
    ekoodi: ["E901", "E 901"],
    nimet: ["Mehiläisvaha", "Mehilaisvaha"],
    kuvaus: [
      "Varmasti eläinperäinen. Mehiläisten erittämää vahaa, josta ne rakentavat kennoja. Käytetään kynttilöissä, kosmetiikassa, pintakäsittelyaineena makeisissa, purukumeissa ja hedelmissä"
    ]
  },
  {
    ekoodi: ["E904", "E 904"],
    nimet: ["Sellakka"],
    kuvaus: [
      "Varmasti eläinperäinen. Intialaisen kilpikuoriaisen eritettä. Sitä käytetään pintakäsittelyaineena mm. makeisissa, lääkkeiden valmistuksessa ja vahoissa."
    ]
  },
  {
    ekoodi: ["E966", "E 966"],
    nimet: ["Laktitoli"],
    kuvaus: [
      "Varmasti eläinperäinen. Laktoosista (maitosokerista) valmistettu makeutusaine, sokerialkoholi. Käytetään joissain lääkkeissä ja light-elintarvikkeissa"
    ]
  },
  {
    ekoodi: ["E1105", "E 1105"],
    nimet: ["Lysotsyymi"],
    kuvaus: [
      "Varmasti eläinperäinen. Kananmunanvalkuaisen proteiini. Käytetään säilöntäaineena esim. juustoissa, suuvesissä, silmätipoissa ja viineissä."
    ]
  },
  {
    ekoodi: ["E270", "E 270"],
    nimet: ["Maitohappo", "Laktaatti"],
    kuvaus: [
      "Mahdollisesti eläinperäinen. Tuotetaan maitohappokäymisen avulla lähes poikkeuksetta kasvitärkkelyksestä. Lääkkeissä ja kosmetiikassa saattaa olla eläinperäinen."
    ]
  },
  {
    ekoodi: ["E304", "E 304"],
    nimet: ["Askorbyylipalmitaatti", "Askorbyylistearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E322", "E 322"],
    nimet: ["Lesitiinit", "Lesitiini"],
    kuvaus: [
      "Mahdollisesti eläinperäinen. Useimmiten peräisin soijasta tai auringonkukasta."
    ]
  },
  {
    ekoodi: ["E325", "E 325"],
    nimet: ["Natriumlaktaatti", "Maitohapon suola"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E326", "E 326"],
    nimet: ["Kaliumlaktaatti", "Maitohapon suola"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E327", "E 327"],
    nimet: ["Kalsiumlaktaatti", "Maitohapon suola"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E422", "E 422", "E 422*"],
    nimet: ["Glyseroli", "glyseriini"],
    kuvaus: [
      "Mahdollisesti eläinperäinen. Käytetään laajasti kosmetiikkatuotteissa, myös ruuissa makeutus-, emulgointi- ja täyteaineena. Voi olla peräisin eläin- tai kasviperäisistä rasvoista."
    ]
  },
  {
    ekoodi: ["E431", "E 431"],
    nimet: ["Polyoksietyleeni", "Stearaatti", "Polyoksietyleeni(40)stearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E432", "E 432"],
    nimet: ["Polyoksietyleenisorbitaanimonolauraatti", "polysorbaatti 20"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E433", "E 433"],
    nimet: ["Polyoksietyleenisorbitaanimono-oleaatti", "polysorbaatti 80"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E434", "E 434"],
    nimet: ["Polyoksietyleenisorbitaanimonopalmitaatti", "polysorbaatti 40"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E435", "E 435"],
    nimet: ["Polyoksietyleenisorbitaanimonostearaatti", "polysorbaatti 60"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E436", "E 436"],
    nimet: ["Polyoksietyleenisorbitaaninitristearaatti", "polysorbaatti 65"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E435", "E 435"],
    nimet: ["Polyoksietyleenisorbitaanimonostearaatti", "polysorbaatti 60"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E445", "E 445"],
    nimet: ["Puuhartsien glyseroliesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E470a", "E 470a"],
    nimet: ["Rasvahappojen natrium-, kalium- ja kalsiumsuolat"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E470b", "E 470b"],
    nimet: ["Rasvahappojen magnesiumsuolat"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E471", "E 471"],
    nimet: ["Rasvahappojen mono- ja diglyseridit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472a", "E 472a"],
    nimet: ["Rasvahappojen mono- ja diglyseridien etikkahappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472b", "E 472b"],
    nimet: ["Rasvahappojen mono- ja diglyseridien maitohappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472c", "E 472c"],
    nimet: ["Rasvahappojen mono- ja diglyseridien sitruunahappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472d", "E 472d"],
    nimet: ["Rasvahappojen mono- ja diglyseridien viinihappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472e", "E 472e"],
    nimet: ["Rasvahappojen mono- ja diglyseridien viinihappoesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E472f", "E 472f"],
    nimet: [
      "Rasvahappojen mono- ja diglyseridien etikka- ja viinihappoesterit"
    ],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E473", "E 473"],
    nimet: ["Rasvahappojen sakkaroosiesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E474", "E 474"],
    nimet: ["Sokeriglyseridit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E475", "E 475"],
    nimet: ["Rasvahappojen polyglyseroliesterit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E476", "E 476"],
    nimet: ["Polyglyserolipolyrisinolaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E477", "E 477"],
    nimet: ["Propyleeniglykolimonostearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E479b", "E 479b"],
    nimet: ["Termisesti hapetettu ja polymeroitu soijaöljy", "TOSOM"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E4811", "E 4811"],
    nimet: ["Natriumstearoyyli-2-laktylaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E4831", "E 4831"],
    nimet: ["Stearyylitartraatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E4821", "E 4821"],
    nimet: ["Kalsiumstearoyyli-2-laktylaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E491", "E 491"],
    nimet: ["Sorbitaanimonostearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E492", "E 492"],
    nimet: ["Sorbitaanitristearaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E493", "E 493"],
    nimet: ["Sorbitaanimonolauraatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E494", "E 494"],
    nimet: ["Sorbitaanimono-oletaatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E495", "E 495"],
    nimet: ["Sorbitaanimonopalmitatti"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E570", "E 570"],
    nimet: ["Rasvahapot"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: [
      "E626",
      "E 626",
      "E627",
      "E 627",
      "E628",
      "E 628",
      "E629",
      "E 629"
    ],
    nimet: ["Guanyylihappo suoloineen"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: [
      "E630",
      "E 630",
      "E631",
      "E 631",
      "E632",
      "E 632",
      "E633",
      "E 633"
    ],
    nimet: ["Inosiinihappo suoloineen"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E634", "E 634"],
    nimet: ["Kalsium-5′-ribonukleotidit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E635", "E 635"],
    nimet: ["Dinatrium-5′-ribonukleotidit"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: ["E920", "E 920"],
    nimet: ["L-kysteiini", "Aminohappo"],
    kuvaus: [
      "Mahdollisesti eläinperäinen. Löytyy terveysvalmisteista ja kosmetiikkatuotteista, muualla maailmassa käytetään laajasti teollisissa leipomotuotteissa. Voi olla synteettinen tai peräisin hiuksista tai höyhenistä."
    ]
  },
  {
    ekoodi: ["E1518", "E 1518"],
    nimet: ["Glyserolitriasetaatti", "triasetiini§"],
    kuvaus: ["Mahdollisesti eläinperäinen."]
  },
  {
    ekoodi: [], //we need to include TARGETS[0] even if it's empty for the current findTargets function
    nimet: ["Lanoliini", "Lanolin"]
  },
  {
    ekoodi: [],
    nimet: ["Hera", "Herajauhe"]
  },
  {
    ekoodi: [],
    nimet: ["Kaseiini", "Kaseinaatit"]
  },
  {
    ekoodi: [],
    nimet: ["Laktoosi"]
  },
  {
    ekoodi: [],
    nimet: ["Albumiini"]
  },
  {
    ekoodi: [],
    nimet: [
      "Munia",
      "Munatuotteita",
      "Kananmuna",
      "Kanan muna",
      "Kanan-muna",
      "Kokomunamassa",
      "Kokomuna massa",
      "Kokomuna-massa",
      "Valkuaismassa",
      "Valkuais massa",
      "Valkuais-massa",
      "Keltuaismassa",
      "Keltuais massa",
      "Keltuais-massa",
      "Munankeltuainen",
      "Munan keltuainen",
      "Munan-keltuainen",
      "Munanvalkuainen",
      "Munan valkuainen",
      "Munan-valkuainen",
      "Munan-valkuainen",
      "Kananmunankeltuainen",
      "Kananmunan keltuainen",
      "Kananmunan-keltuainen",
      "Kananmunanvalkuainen",
      "Kananmunan valkuainen",
      "Kananmunan-valkuainen",
      "Munajauhe",
      "Kokomunajauhe",
      "Kokomuna jauhe",
      "Kokomuna-jauhe",
      "Valkuaisjauhe",
      "Valkuais jauhe",
      "Valkuais-jauhe",
      "Munavoi",
      "Muna voi",
      "Muna-voi",
      "Nestemäinen valkuainen",
      "Nestemainen valkuainen"
    ]
  },
  {
    ekoodi: [],
    nimet: ["Pepsiini", "Renniini"]
  },
  {
    ekoodi: [],
    nimet: ["Liivate", "Gelatiini"]
  },
  {
    ekoodi: [],
    nimet: ["Tali", "Laardi", "Ihra"]
  },
  {
    ekoodi: [],
    nimet: ["Steariini"]
  },
  {
    ekoodi: [],
    nimet: ["Lanoliini"]
  },
  {
    ekoodi: [],
    nimet: ["Kolekalsiferoli"]
  },
  {
    ekoodi: [],
    nimet: ["Sappi"]
  },
  {
    ekoodi: [],
    nimet: ["Hunaja"]
  },
  {
    ekoodi: [],
    nimet: [
      "Meijerituote",
      "Maito",
      "Maitorasva",
      "Lehmänmaito",
      "Lehmanmaito",
      "Lampaanmaito",
      "Vuohenmaito",
      "Vesipuhvelinmaito",
      "Jakinmaito",
      "Raakamaito",
      "Maitojauhe",
      "Maito jauhe",
      "Täysmaitojauhe",
      "Taysmaitojauhe",
      "Voi",
      "Imelävoi",
      "Imelavoi",
      "Hapatettu voi",
      "Kirkastettu voi",
      "Kirnuvoi",
      "Kirnu voi",
      "Lypsetty",
      "Lypsy",
      "Täysmaito",
      "Maidosta",
      "Kirnumaitojauhe",
      "Suklaa",
      "Maito-jauhe",
      "Kerma",
      "Kuohukerma",
      "Kahvikerma",
      "Vispikerma",
      "Kevytkerma",
      "RuokaKerma",
      "Piimä",
      "Piima",
      "A-piimä",
      "A piimä",
      "Apiimä",
      "A-piima",
      "A piima",
      "Apiima",
      "Talouspiimä",
      "Talous piimä",
      "Talouspiima",
      "Talous-piimä",
      "Talous-piima",
      "Rasvaton piimä",
      "Rasvatonpiimä",
      "Rasvatonpiima",
      "Rasvaton piima",
      "Viili",
      "Jogurtti",
      "Maitorahka",
      "Maito rahka",
      "Kermaviili",
      "Kerma viili",
      "Ranskankerma",
      "Ranskan kerma",
      "Smetana",
      "Juusto",
      "Emmental",
      "Edam",
      "Fontal",
      "Tilsit",
      "Port salut",
      "Portsalut",
      "Port-salut",
      "Cheddar",
      "Gouda",
      "Homejuusto",
      "Home juusto",
      "Tuorejuusto",
      "Tuore juusto",
      "Leipäjuusto",
      "Leipajuusto",
      "Leipä juusto",
      "Leipajuusto",
      "Leipa juusto",
      "Kotijuusto",
      "Koti juusto",
      "Sulatejuusto",
      "Sulate juusto",
      "Jäätelö",
      "Jaatelo",
      "Vanukas",
      "Pästroitu",
      "Pastroitu",
      "Hapate",
      "Ternimaito",
      "Terni maito",
      "Kermavaahto",
      "Kerma vaahto"
    ]
  },
  {
    ekoodi: [],
    nimet: [
      "Kuningatarhyytelö",
      "Kuningatarhyytelo",
      "Royal Jelly",
      "RoyalJelly",
      "Emomaito",
      "Emo maito",
      "Emo-maito"
    ]
  },
  {
    ekoodi: [],
    nimet: ["Siitepöly", "Siitepoly"]
  },
  {
    ekoodi: [],
    nimet: ["Propolis", "Kittivaha"]
  },
  {
    ekoodi: [],
    nimet: [
      "Lihapitoisuus",
      "Liha pitoisuus",
      "Liha-pitoisuus",
      "Lihaprosentti",
      "Liha prosentti",
      "Liha-prosentti",
      "Lihaproteiini",
      "Liha proteiini",
      "Liha-proteiini",
      "Jauheliha",
      "Kinkku",
      "Kokoliha",
      "Täysliha",
      "Pekoni",
      "Lenkkimakkara",
      "Grillimakkara",
      "Nakki",
      "Meetvursti",
      "Salami",
      "Maksa",
      "Niska",
      "Etuselkä",
      "Etuselka",
      "Ulkofile",
      "Sisäfile",
      "Sisafile",
      "Ulkopaisti",
      "Lapa",
      "Kylki",
      "Kulmapaisti",
      "Sisäpaisti",
      "Sisapaisti",
      "Paahtopaisti",
      "Potka",
      "Rinta",
      "Koipireisi",
      "Rintafile",
      "Rintaleike",
      "Siipi",
      "Siivet",
      "Koipipala",
      "Koipipalat",
      "Reisipala",
      "Reisipalat",
      "Koipi- ja reisipalat",
      "Reisi- ja koipipalat",
      "Kaula",
      "Etuselkä",
      "Etuselka",
      "Kyjysselkä",
      "Kyljysselka",
      "Luullinen",
      "Luu",
      "Maksamakkara",
      "Maksapasteija",
      "Lihahyytelö",
      "Sisäelimet",
      "Sisaelimet",
      "Kyljys",
      "Riista"
    ]
  },
  {
    ekoodi: [],
    nimet: [
      "Porsaanliha",
      "Porsaan liha",
      "Porsaan-liha",
      "Rypsiporsas",
      "Naudanliha",
      "Naudan liha",
      "Naudan-liha",
      "Siipikarja",
      "Siipikarjan",
      "Siipikarjanliha",
      "Siipikarjan liha",
      "Siipikarjannahka",
      "Siipikarjan nahka",
      "Broilerinliha",
      "Broilerin liha",
      "Broilerin-liha",
      "Kananliha",
      "Kanan liha",
      "Kanan-liha",
      "Kalkkunanliha",
      "Kalkkunan liha",
      "Kalkkunan-liha",
      "Silava",
      "Kamara",
      "Sianliha",
      "Sian liha",
      "Sian-liha",
      "Siansuoli",
      "Sian suoli",
      "Sian-suoli",
      "Hevosenliha",
      "Hevosen liha",
      "Hevosen-liha",
      "Lampaanliha",
      "Lampaan liha",
      "Lampaan-liha",
      "Poronliha",
      "Poron liha",
      "Poron-liha",
      "Vuoheliha",
      "Vuohen liha",
      "Vuohen-liha",
      "Vesipuhvelinliha",
      "Vesipuhvelin liha",
      "Vesipuhvelin-liha",
      "Jakinliha",
      "Jakin liha",
      "Jakin-liha",
      "Jäniksenliha",
      "Jäniksen liha",
      "Jäniksen-liha",
      "Kaninliha",
      "Kanin liha",
      "Kanin-liha",
      "Hirvenliha",
      "Hirven liha",
      "Hirven-liha",
      "Peuranliha",
      "Peuran liha",
      "Peuran-liha",
      "Metsäkauriinliha",
      "Metsäkauriin liha",
      "Metsäkauriin-liha",
      "Kanan",
      "Broilerin",
      "Kalkkunna",
      "Naudan",
      "Porsaan",
      "Sian",
      "Hevosen",
      "Lehmän",
      "Lehman",
      "Lampaan",
      "Poron",
      "Vuohen",
      "Vesipuhvelin",
      "Jakin",
      "Jäniksen",
      "Janiksen",
      "Kanin",
      "Hirven",
      "Peuran",
      "Metsäkauriin",
      "Metsakauriin",
      "Nauta",
      "Porsas",
      "Kana",
      "Broileri",
      "Kalkkuna",
      "Sika",
      "Lehmä",
      "Lehma",
      "Lammas",
      "Poro",
      "Vuohi",
      "Vesipuhveli",
      "Jakki",
      "Luonnonsuoli",
      "Luonnonsuolessa",
      "Teurastettu"
    ]
  },
  {
    ekoodi: [],
    nimet: [
      "MSC",
      "Pyydystyyppi",
      "Trooli",
      "Troolit",
      "Nuotat",
      "Nuotta",
      "Verkot",
      "Koukut",
      "Siimat",
      "Laahusreet",
      "Merrat",
      "Sulkypyydykset",
      "pelagisellatroolilla",
      "Saartopyydys",
      "Saartopyydyksillä",
      "Nostoverkko",
      "Nostoverkoilla"
    ]
  },
  {
    ekoodi: [],
    nimet: [
      "Kala",
      "Kalatuotteita",
      "kalalaji",
      "Lohi",
      "Kyttyrälohi",
      "Oncorhynchus gorbuscha",
      "Theragra chalcogramma",
      "Lepidocybium flavobrunneum",
      "Pollachius virens",
      "Kirjolohi",
      "Ahven",
      "Anjovis",
      "Hauki",
      "Kiiski",
      "Kuha",
      "Lahna",
      "Pasuri",
      "Sorva",
      "Siika",
      "Säyne",
      "Turska",
      "Särki",
      "Silli",
      "Kilohaili",
      "Silakka",
      "Sardiini",
      "Makrilli",
      "Muikku",
      "Eskolaari",
      "Made",
      "Siian",
      "Mateen",
      "Muikun",
      "Särjen",
      "Seiti",
      "ALASKANSEITI",
      "Hauen",
      "Lohen",
      "Seidin",
      "Anjoviksen",
      "Kalamureke",
      "Kalasäilyke",
      "Kalatäyssäilyke",
      "Lipeäkala",
      "Lipeakala",
      "Kalatahna",
      "Tonnikala",
      "Tonni kala",
      "Tonni-kala",
      "Tonnikala boniitti",
      "Tonnikala-boniitti",
      "Boniitti",
      "Skipjack",
      "Katsuwonus pelamis",
      "Atlantin sinievätonnikala",
      "Thunnus maccoyii",
      "Tyynenmeren sinievätonnikala",
      "Eteläinen sinievätonnikala",
      "Isosilmätonnikala",
      "Keltaevätonnikala",
      "Valkotonnikala",
      "Mäti",
      "Graavi",
      "Graavikala",
      "Mätitahna"
    ]
  },
  {
    ekoodi: [],
    nimet: [
      "Kotisirkka",
      "Acheta domesticus",
      "Kanatunkkari",
      "Toukka",
      "Alphitobius diaperinus",
      "Mehiläinen",
      "Kuhnuritoukka",
      "Trooppinen kotisirkka",
      "Gryllodes sigillatus",
      "Mustasotilaskärpänen",
      "Hermetia illucens",
      "Idänkulkusirkka",
      "Locusta migratoria",
      "aavikkokulkusirkka",
      "Schistocerca gregaria",
      "Jauhopukki",
      "Tenebrio molitor"
    ]
  },
  {
    ekoodi: [],
    nimet: [
      "Äyriäisiä",
      "Äyriäistuotteita",
      "Nilviäisiä",
      "Nilviäistuotteita",
      "Pandalus borealis",
      "Procambarusclarkii",
      "Litopenaeus vannamei",
      "Penaeus vannamei",
      "Procambarusclarkii",
      "Paphia undulata",
      "Mytilus Chilensis",
      "Pectinidae",
      "homarus americanus",
      "Ravut",
      "Rapu",
      "Hummerit",
      "Hummeri",
      "Simpukat",
      "Simpukka",
      "Venussimpukka",
      "Sinisimpukka",
      "Kampasimpukka",
      "Katkarapu",
      "Katkarapuja",
      "Katkaravut",
      "Jokirapu",
      "Jokirapuja",
      "Jokiravut",
      "Ravunpyrstö",
      "Ravunpyrstöjä",
      "Ravunpyrstöt",
      "Mustekala"
    ]
  }
];
module.exports = TARGETS;
