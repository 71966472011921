/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("VegaaniKaveri toimii suoraan laitteesi välimuistista :)");
    },
    registered() {
      console.log("VegaaniKaveri optimoitu laitteellesi");
    },
    cached() {
      console.log("VegaaniKaveri on tallennettu laitteesi välimuistiin");
    },
    updatefound() {
      console.log("Päivitystä ladataan...");
    },
    updated() {
      console.log(
        "Uusin päivitys ladattu, refreshaa VegaaniKaveri saadaksesi uusin päivitys käyttöön."
      );
    },
    offline() {
      console.log(
        "VegaaniKaveri vaatii internet yhteyden tunnistaakseen eläinperäisiä ainesosia"
      );
    },
    error(error) {
      console.error("Virhe rekisteröitäessä service workeriä ", error);
    }
  });
}
