<template>
  <main id="app">
    <first v-if="first" v-on:closeFirst="closeFirst" />
    <recognize v-if="!first" />
  </main>
</template>

<script>
import first from "./components/first.vue";
import recognize from "./components/recognize.vue";

export default {
  name: "App",
  components: {
    first,
    recognize
  },
  data: function() {
    return {
      first: true
    };
  },
  created() {
    if (localStorage.getItem("notFirst")) {
      this.first = false;
    } else return;
  },
  methods: {
    closeFirst(value) {
      this.first = value;
      localStorage.setItem("notFirst", "true");
    }
  }
};
</script>

<style lang="scss">
@import "../src/scss/styles.scss";
</style>
